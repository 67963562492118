import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vlm-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  objectives: ChoiceCard[] = [];
  sizes: ChoiceCard[] = [];

  selectedObjective: ChoiceCard;
  selectedSize: ChoiceCard;

  data;

  constructor(private router: Router) {}

  ngOnInit() {
    const data = JSON.parse(localStorage.getItem('data'));

    if (data) {
      this.data = data;
      this.objectives = [];
      for (let i = 0; i < data.objectives.length; i++) {
        const objective = data.objectives[i];

        this.objectives.push({
          title: objective.title,
          description: objective.description,
          sizes: objective.sizes,
          template: objective.template,
          icon: objective.icon,
          sizesIcon: objective.sizesIcon,
        });
      }
      console.log('Loaded in', this.objectives?.length, 'objectives');
    }

    this.reselect();
  }

  toggleObjective(objective: ChoiceCard) {
    if (this.selectedObjective === objective) {
      this.selectedObjective = null;
      this.selectedSize = null;
      localStorage.removeItem('objective');
      localStorage.removeItem('size');
      this.setTemplate();
      return;
    }

    this.sizes = [];
    localStorage.removeItem('size');
    this.selectedObjective = objective;
    this.sizes = objective.sizes;
    localStorage.setItem('objective', JSON.stringify(objective));

    this.setTemplate();
  }

  toggleSize(size: ChoiceCard) {
    if (this.selectedSize === size) {
      this.selectedSize = null;
      localStorage.removeItem('size');
      return;
    }

    this.selectedSize = size;
    localStorage.setItem('size', JSON.stringify(size));
  }

  setTemplate() {
    console.log(this.selectedObjective?.template);
    if (this.selectedObjective?.template) {
      localStorage.setItem(
        'template',
        JSON.stringify(this.selectedObjective.template),
      );
    } else {
      localStorage.removeItem('template');
    }

    localStorage.removeItem('contentDataURL');
    localStorage.removeItem('segments');
    localStorage.removeItem('serverTypes');
    localStorage.removeItem('serviceTypes');
    localStorage.removeItem('svs');
  }

  continue() {
    this.router.navigateByUrl('step2');
  }

  private reselect() {
    let ls = localStorage.getItem('objective');
    if (ls) {
      const storedObjective = JSON.parse(ls);
      if (storedObjective) {
        this.selectedObjective = this.objectives.find(
          (objective) => objective.title === storedObjective.title,
        );

        console.log('Reselected objective:', this.selectedObjective?.title);
        this.sizes = this.selectedObjective?.sizes;
      }
    }

    ls = localStorage.getItem('size');
    if (ls) {
      const storedSize = JSON.parse(ls);
      if (storedSize && this.sizes) {
        this.selectedSize = this.sizes.find(
          (size) => size.title === storedSize.title,
        );
        console.log('Reselected size:', this.selectedSize.title);
      }
    }
  }
}

export type ChoiceCard = {
  icon?: string;
  sizesIcon?: string;
  title: string;
  description: string;
  sizes?: ChoiceCard[];
  template?: any;
};
