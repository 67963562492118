<div>
  <div mat-dialog-title>Configured Range View</div>
  <mat-dialog-content class="range-dialog-content">
    <mat-divider></mat-divider>

    <div class="range-wrapper" id="range-wrapper" #screen>
      <!--      <svg id="fullsvg"></svg>-->

      <div class="grid-box internet" id="internet">Internet</div>

      <div class="fw1 no-box" id="fw1">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
        FW
      </div>
      <div class="ram no-box" id="ram1">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
      </div>

      <div class="pc no-box" id="pc1">
        <vlm-icon [inputIcon]="'fas fa-desktop'"></vlm-icon>
      </div>

      <div class="pc no-box" id="pc2">
        <vlm-icon [inputIcon]="'fas fa-desktop'"></vlm-icon>
      </div>

      <div class="ram-mid no-box" id="ram2">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
      </div>
      <div class="ram-mid no-box" id="ram3">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
      </div>
      <div class="ram-mid no-box" id="ram4">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
      </div>
      <div class="ram-mid no-box" id="fw2">
        <vlm-icon [inputIcon]="'ram'"></vlm-icon>
        FW
      </div>

      <div class="scenarios">
        <div>Scenarios & Vulnerabilities</div>
        @for (sv of scenariosAndVulnerabilities; track sv) {
          <div class="scenario disable-uppercase">
            {{ sv.name }}
          </div>
        }
      </div>

      @for (segment of segments; track segment) {
        @if (segment.type) {
          @switch (segment.type.toLowerCase()) {
            @case ("dev") {
              <div class="grid-box dev" id="dev">
                <ng-container
                  *ngTemplateOutlet="
                    nodeContent;
                    context: {
                      segment: segment
                    }
                  "
                ></ng-container>
              </div>
            }
            @case ("cloud") {
              <div class="grid-box cloud" id="cloud">
                <ng-container
                  *ngTemplateOutlet="
                    nodeContent;
                    context: {
                      segment: segment
                    }
                  "
                ></ng-container>
              </div>
            }
            @default {
              <div class="grid-box network-segment">
                <ng-container
                  *ngTemplateOutlet="
                    nodeContent;
                    context: {
                      segment: segment
                    }
                  "
                ></ng-container>
              </div>
            }
          }
        }
      }
    </div>
    <div id="image"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="screenshot()">screen</button>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>

<ng-template #nodeContent let-segment="segment">
  <cybexer-row>
    <div class="title">
      {{ segment.title }}
    </div>
    <cybexer-row>
      @for (server of segment.servers; track server.title) {
        <div class="icon">
          <vlm-icon [inputIcon]="server.type"></vlm-icon>
        </div>
      }
    </cybexer-row>
  </cybexer-row>
</ng-template>
