<div class="vlm-layout">
  <header class="vlm-header">
    <mat-toolbar class="vlm-header-toolbar">
      <div class="vlm-header-left">
        <button mat-icon-button (click)="toggleNav()">
          <i class="fas fa-bars fa-lg" aria-hidden="true"></i>
        </button>
        <div>
          <span class="vlm-title">{{ title }}</span>
        </div>
      </div>

      <div class="vlm-header-center"></div>

      <div class="vlm-header-right">
        <div class="beta-box"></div>
        <div class="beta-label">BETA</div>
      </div>
    </mat-toolbar>
  </header>
  <mat-sidenav-container class="vlm-sidenav-container">
    <mat-sidenav
      #nav
      mode="side"
      [opened]="!isSideNavHidden"
      class="vlm-sidenav"
    >
      <mat-nav-list class="nav-list">
        <a
          mat-list-item
          [routerLink]="['step1']"
          class="link-item"
          routerLinkActive="active"
        >
          <span matListItemTitle class="link-title">Step 1</span>
          <span matListItemLine class="disable-uppercase"
            >Tell us about your project</span
          >
        </a>
        <a
          mat-list-item
          [routerLink]="['step2']"
          class="link-item"
          routerLinkActive="active"
        >
          <span matListItemTitle class="link-title">Step 2</span>
          <span matListItemLine class="disable-uppercase"
            >Customize your Digital Twin</span
          >
          <span matListItemLine class="disable-uppercase"
            >Specify network, systems and tools</span
          >
        </a>
        <a
          mat-list-item
          [routerLink]="['step3']"
          class="link-item"
          routerLinkActive="active"
        >
          <span matListItemTitle class="link-title">Step 3</span>
          <span matListItemLine class="disable-uppercase"
            >Review, download and contact</span
          >
        </a>
      </mat-nav-list>

      <cybexer-confirm-action>
        <button class="reload-btn" mat-button (click)="refreshAllData()">
          <mat-icon>refresh</mat-icon> Clear data and restart
        </button>
      </cybexer-confirm-action>
    </mat-sidenav>
    <div class="vlm-content">
      <main #contentBody class="vlm-content-body">
        <div class="body-wrapper">
          <cybexer-loading-overlay>
            <router-outlet></router-outlet>
          </cybexer-loading-overlay>
        </div>
      </main>
      <footer class="vlm-footer">
        <mat-toolbar class="vlm-footer-toolbar">
          <div class="vlm-version-container ellipsis">
            <!--            <span>{{ "xxx-xx-xx" }}</span>-->
          </div>
          <div class="vlm-logo-container">
            <!--            <div class="logo-text ellipsis">Digital Twin Configurator</div>-->
            <!--            <img class="vlm-logo" src="./assets/img/vlm-logo.svg" />-->
            <div class="logo-text ellipsis">
              POWERED BY CYBEXER TECHNOLOGIES
            </div>
            <img class="cybexer-logo" src="./assets/img/cybexer-logo.png" />
          </div>
        </mat-toolbar>
      </footer>
    </div>
  </mat-sidenav-container>
</div>
