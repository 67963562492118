import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Segment, Selection } from '../step2.component';
import html2canvas from 'html2canvas';
import { interval, startWith } from 'rxjs';
import { NgxCaptureService } from 'ngx-capture';

declare var LeaderLine: any;

@Component({
  selector: 'vlm-configured-range-dialog',
  templateUrl: './configured-range-dialog.component.html',
  styleUrls: ['./configured-range-dialog.component.scss'],
})
export class ConfiguredRangeDialogComponent implements OnInit, AfterViewInit {
  segments: Segment[] = [];
  scenariosAndVulnerabilities: Selection[] = [];
  lines = [];
  private contentDataURL: string;
  @ViewChild('screen', { static: true }) screen: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfiguredRangeDialogComponent>,
    private renderer: Renderer2,
    private captureService: NgxCaptureService,
  ) {}

  ngOnInit(): void {
    this.segments = JSON.parse(localStorage.getItem('segments')).filter(
      (segment) => segment.selected,
    );

    this.scenariosAndVulnerabilities = JSON.parse(
      localStorage.getItem('svs'),
    ).filter((sv) => sv.selected);

    interval(10)
      .pipe(startWith(0))
      .subscribe(() => {
        this.redrawLines();
      });
  }

  ngAfterViewInit() {
    this.drawLine('fw1', 'ram1');
    this.drawLine('fw1', 'internet');
    this.drawLine('ram2', 'internet');
    this.drawLine('ram2', 'ram4');
    this.drawLine('ram3', 'internet');
    this.drawLine('ram3', 'ram4');
    this.drawLine('pc1', 'ram1');
    this.drawLine('pc2', 'ram1');
    this.drawLine('ram4', 'ram1');
    this.drawLine('ram4', 'fw2');

    this.rerenderLines();

    // this.screenshot();
  }

  get devTypeSegment() {
    return this.segments.find((s) => s.type && s.type.toLowerCase() === 'dev');
  }

  private rerenderLines(): void {
    const panZoomElement = document.querySelector('.range-wrapper');

    // it seems like re-rendering leader-line-defs doesn't do much, however I think it might be better
    // to add it to the pan-zoom with the rest of the leader-lines
    const leaderLineDefs = document.querySelector('#leader-line-defs');
    if (leaderLineDefs != null) {
      this.renderer.appendChild(panZoomElement, leaderLineDefs);
    }

    document.querySelectorAll('.leader-line').forEach((lineEl) => {
      this.renderer.appendChild(panZoomElement, lineEl);
    });
  }

  screenshot() {
    var svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function (item) {
      // @ts-ignore
      item.setAttribute('width', item.getBoundingClientRect().width);
      // @ts-ignore
      item.setAttribute('height', item.getBoundingClientRect().height);
      // item.style.width = null;
      // item.style.height = null;
    });
    console.log(svgElements);

    const imagePromise = html2canvas(
      document.getElementById('range-wrapper'),
    ).then((canvas) => {
      this.contentDataURL = canvas.toDataURL('image/png');
      // console.log('contentDataURL', this.contentDataURL);

      document.getElementById('image').appendChild(canvas);
    });
  }

  // drawLine(el1, el2): void {
  //   var b1 = document.getElementById(el1).getBoundingClientRect();
  //   var b2 = document.getElementById(el2).getBoundingClientRect();
  //   var newLine = document.createElementNS(
  //     'http://www.w3.org/2000/svg',
  //     'line',
  //   );
  //   newLine.setAttribute('id', 'line1');
  //   // @ts-ignore
  //   newLine.setAttribute('x1', b1.left + b1.width / 2);
  //   // @ts-ignore
  //   newLine.setAttribute('y1', b1.top + b1.height / 2);
  //   // @ts-ignore
  //   newLine.setAttribute('x2', b2.left + b2.width / 2);
  //   // @ts-ignore
  //   newLine.setAttribute('y2', b2.top + b2.height / 2);
  //   newLine.setAttribute(
  //     'style',
  //     'stroke: red; stroke-width: 3; z-index: 9999999999',
  //   );
  //   document.getElementById('fullsvg').append(newLine);
  // }

  drawLine(el1, el2): void {
    const element1 = document.getElementById(el1);
    const element2 = document.getElementById(el2);

    let line = new LeaderLine(element1, element2, {
      color: '#c43d3d',
      size: 2,
      startPlug: 'behind',
      endPlug: 'behind',
      path: 'straight',
    });

    this.lines.push(line);
  }

  redrawLines(): void {
    this.lines.forEach((line) => line.position());
  }
}
