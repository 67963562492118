import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Theme } from '@models/shared/theme.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Digital Twin Configurator';

  private currentTheme = Theme.DARK;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.currentTheme =
      (localStorage.getItem('activeTheme') as Theme) || Theme.DARK;
    this.renderer.setAttribute(this.document.body, 'class', this.currentTheme);
  }
}
