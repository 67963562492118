<div>
  <div mat-dialog-title>{{ dialogTitle }}</div>
  <mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-form-field class="name-edit-field">
      <mat-label>Name</mat-label>
      <input matInput type="text" [(ngModel)]="title" />
    </mat-form-field>
    <mat-selection-list [multiple]="false" [(ngModel)]="type">
      @for (option of options; track option) {
        <mat-list-option [value]="option">{{ option }}</mat-list-option>
      }
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="saveAndClose()" color="primary">
      <mat-icon>save</mat-icon>save and close
    </button>
  </mat-dialog-actions>
</div>
