import { Injectable, Injector, NgZone } from '@angular/core';
import { CommonErrorHandler, NotificationsService } from '@cybexer/ngx-commons';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends CommonErrorHandler {
  constructor(
    injector: Injector,
    notificationsService: NotificationsService,
    zone: NgZone
  ) {
    super(injector, notificationsService, zone);
  }

  get loginPage(): string {
    return '/login';
  }

  getErrorMessage(errorResponse?: any): string {
    if (errorResponse == null) {
      return '';
    }

    if (errorResponse.status !== 500) {
      return errorResponse.error.errors[0];
    }

    return '';
  }
}
