<cybexer-widget-header
  [title]="'Step 1'"
  [inline]="true"
></cybexer-widget-header>

<cybexer-widget-body>
  <mat-card class="full-card separate">
    <mat-card-title>
      Welcome to the
      <div>Digital Twin Configurator</div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <div class="disable-uppercase description">
      This tool is designed to help you design a digital twin that mirrors your
      technology landscape. By describing your project, you embark on a journey
      to develop a solution perfectly tailored to your specifications. It
      ensures you start with a template that’s aligned with your needs, allowing
      for a digital twin that accurately reflects your technology landscape,
      addresses your objectives, and scales appropriately with your project
      size.
    </div>
    <mat-divider></mat-divider>

    <div class="choice-cards-title">Choose primary objective</div>
    <div class="choice-cards">
      @for (objective of objectives; track objective.title) {
        <div
          class="choice-card"
          (click)="toggleObjective(objective)"
          [class.selected-card]="objective.title === selectedObjective?.title"
        >
          <div class="icon-wrapper">
            <vlm-icon
              [inputIcon]="objective.icon"
              [mainClass]="'icon'"
            ></vlm-icon>
          </div>
          <div class="title">
            {{ objective.title }}
          </div>

          <div class="description disable-uppercase">
            {{ objective.description }}
          </div>
        </div>
      }
    </div>

    <div class="choice-cards-title" *ngIf="sizes?.length">Choose Size</div>
    <div class="choice-cards">
      @for (
        size of sizes;
        track size;
        let first = $first;
        let last = $last
      ) {
        <div
          class="choice-card"
          (click)="toggleSize(size)"
          [class.selected-card]="size.title === selectedSize?.title"
        >
          <div class="icon-wrapper">
            <vlm-icon
              *ngIf="!first"
              [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
            ></vlm-icon>
            <vlm-icon
              [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
              [mainClass]="'icon'"
            ></vlm-icon>
            <vlm-icon
              *ngIf="last"
              [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
            ></vlm-icon>
          </div>
          <div class="title">
            {{ size.title }}
          </div>

          <div class="description disable-uppercase">
            {{ size.description }}
          </div>
        </div>
      }
    </div>

    <div class="actions">
      <button mat-raised-button color="primary" (click)="continue()">
        Continue to step 2/3
      </button>
    </div>
  </mat-card>
</cybexer-widget-body>
